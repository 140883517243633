<template>
  <b-modal
    id="modal-directions"
    v-model="modalDir"
    size="lg"
    centered
    hide-header
    hide-footer
  >
    <h3 class="mb-4 text-center">Añadir nueva dirección</h3>
    <div class="container-modal-direccions">
      <div class="col-12 col-lg-6 mb-4 px-md-3">
        <div class="input-simple-clover">
          <label
            :class="validateDir ? 'invalid' : ''"
            class="label-input"
            :style="dir ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Dirección</label
          >
          <input
            v-model="dir"
            type="text"
            maxlength="50"
            placeholder="Dirección"
            autocomplete="address-line1"
            :class="validateDir ? 'invalid-error' : ''"
            @blur="validateBlur('validateDir')"
            @focus="validateDir = false"
          />
          <span v-if="validateDir" class="inavalida-input">{{
            dir ? 'Nombre invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-4 px-md-3">
        <div class="input-simple-clover">
          <label
            class="label-input"
            :style="barrio ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Barrio</label
          >
          <input
            v-model="barrio"
            placeholder="Barrio"
            maxlength="50"
            type="text"
            autocomplete="address-line2"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-4 px-md-3">
        <div class="input-simple-clover">
          <label
            :class="validateDep ? 'invalid' : ''"
            class="label-input"
            :style="dep ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Departamento</label
          >
          <select
            v-model="dep"
            :class="validateDep ? 'invalid-error' : ''"
            type="number"
            class="w-100"
            @blur="validateBlur('validateDep')"
            @focus="validateDep = false"
          >
            <option :value="null">Departamento</option>
            <option
              v-for="(mun1, index2) in departamentos"
              :key="index2"
              :value="mun1"
            >
              {{
                mun1.descripcion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </option>
          </select>
          <span v-if="validateDep" class="inavalida-input"
            >Campo requerido</span
          >
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-4 px-md-3">
        <div class="input-simple-clover">
          <label
            :class="validateMun ? 'invalid' : ''"
            class="label-input"
            :style="mun ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Ciudad</label
          >
          <select
            v-model="mun"
            :class="validateMun ? 'invalid-error' : ''"
            type="number"
            class="w-100"
            @blur="validateBlur('validateMun')"
            @focus="validateMun = false"
          >
            <option :value="null">Ciudad</option>
            <option
              v-for="(ciudad, index2) in ciudades"
              :key="index2"
              :value="ciudad"
            >
              {{
                ciudad.descripcion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </option>
          </select>
          <span v-if="validateMun" class="inavalida-input"
            >Campo requerido</span
          >
        </div>
      </div>
      <div class="col-12 px-md-3 mb-4">
        <div class="input-simple-clover">
          <label
            class="label-input"
            :style="observaciones ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Observaciones</label
          >
          <input
            v-model="observaciones"
            placeholder="Observaciones"
            type="text"
            maxlength="50"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6 mx-auto px-md-3 mb-4">
        <button class="w-100 bnt-primary" @click="validateDataDir()">
          {{ isEdit ? 'Editar dirección' : 'Agregar dirección' }}
        </button>
      </div>
      <div v-if="errorDir" class="w-100">
        <span class="text-error">{{ msgErrorDir }}</span>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    isEdit: {
      type: Boolean,
      required: false,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      ciudades: [],
      idDir: null,
      modalDir: false,
      dir: '',
      validateDir: false,
      barrio: '',
      dep: null,
      validateDep: false,
      mun: null,
      validateMun: false,
      observaciones: '',
    }
  },
  computed: {
    ...mapState('user', [
      'dataUser',
      'departamentos',
      'errorDir',
      'msgErrorDir',
    ]),
  },
  watch: {
    async isEdit() {
      if (this.isEdit) {
        this.idDir = this.dataEdit.idDir
        this.dir = this.dataEdit.dir
        this.barrio = this.dataEdit.barrio
        this.dep = await this.departamentos.find(
          element => element.codigo == this.dataEdit.dep,
        )
        this.mun = await this.dep.ciudades.find(
          element => element.codigo == this.dataEdit.mun,
        )
        this.observaciones = this.dataEdit.observaciones
      } else {
        this.idDir = null
        this.dir = ''
        this.validateDir = false
        this.barrio = ''
        this.dep = null
        this.validateDep = false
        this.mun = null
        this.validateMun = false
        this.observaciones = ''
      }
    },
    async dataEdit() {
      if (this.isEdit) {
        this.idDir = this.dataEdit.idDir
        this.dir = this.dataEdit.dir
        this.barrio = this.dataEdit.barrio
        this.dep = await this.departamentos.find(
          element => element.codigo == this.dataEdit.dep,
        )
        this.mun = await this.dep.ciudades.find(
          element => element.codigo == this.dataEdit.mun,
        )
        this.observaciones = this.dataEdit.observaciones
      } else {
        this.idDir = null
        this.dir = ''
        this.validateDir = false
        this.barrio = ''
        this.dep = null
        this.validateDep = false
        this.mun = null
        this.validateMun = false
        this.observaciones = ''
      }
    },
    dep() {
      if (this.dep) {
        this.arrayMun = []
        this.ciudades = this.dep.ciudades
      } else {
        this.arrayMun = []
      }
    },
  },
  async mounted() {
    if (this.departamentos.length == 0) {
      await this.getCiudades()
    }
  },
  methods: {
    ...mapActions('user', ['getCiudades', 'addDir', 'updateDir']),
    validateBlur(input) {
      if (input == 'validateDir') {
        this.validateDir = !(this.dir.length > 4)
      } else if (input == 'validateDep') {
        this.validateDep = !this.dep
      } else if (input == 'validateMun') {
        this.validateMun = !this.mun
      }
    },
    async validateDataDir() {
      if (this.dir.length > 4 && this.dep && this.mun) {
        if (this.isEdit) {
          await this.updateDir({
            idDir: this.idDir,
            dir: this.dir,
            mun: this.mun.codigo,
            dep: this.dep.codigo,
            barrio: this.barrio,
            observaciones: this.observaciones,
          }).then(() => {
            if (!this.errorDir) {
              this.modalDir = false
              this.ciudades = []
              this.idDir = null
              this.dir = ''
              this.validateDir = false
              this.barrio = ''
              this.dep = null
              this.validateDep = false
              this.mun = null
              this.validateMun = false
              this.observaciones = ''
            }
          })
        } else {
          await this.addDir({
            dir: this.dir,
            mun: this.mun.codigo,
            dep: this.dep.codigo,
            barrio: this.barrio,
            observaciones: this.observaciones,
          }).then(() => {
            if (!this.errorDir) {
              this.modalDir = false
              this.ciudades = []
              this.idDir = null
              this.dir = ''
              this.validateDir = false
              this.barrio = ''
              this.dep = null
              this.validateDep = false
              this.mun = null
              this.validateMun = false
              this.observaciones = ''
            }
          })
        }
      } else {
        this.validateDir = !(this.dir.length > 4)
        this.validateDep = !this.dep
        this.validateMun = !this.mun
      }
    },
  },
}
</script>
