<template>
  <div class="component-profile">
    <div v-if="dataUser" class="container-mis-direcciones">
      <div class="col-12 col-md-6 col-lg-4 mb-4 px-0 px-md-3">
        <div class="container-add-new-dir" @click="addDirUser()">
          <img src="@/assets/icons/icon-plus-circular.svg" alt="" />
          <p class="w-100">Agregar nueva dirección</p>
        </div>
      </div>
      <div
        v-for="(direction, index) in directions"
        :key="index"
        class="col-12 col-md-6 col-lg-4 mb-4"
      >
        <div class="container-direction-card">
          <div class="actions-card-dir">
            <span class="edit-dir" @click="updateDirUser(direction)"
              >Editar
            </span>
            <span v-if="directions.length > 1" class="px-1"> - </span>
            <span
              v-if="directions.length > 1"
              class="delete-dir"
              @click="deleteDirection(direction.direccion_id)"
            >
              Eliminar</span
            >
          </div>
          <p class="mb-0">
            <b>{{ direction.direccion }}</b> <br />
            {{ direction.barrio }} <br />
            {{
              direction.ciudad_texto
                .split(',')[0]
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }},
            {{
              direction.ciudad_texto
                .split(',')[1]
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
            <br />
            {{ direction.observaciones }} <br />
          </p>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <h3>Aún no has iniciado sesión</h3>
      <p>Para poder acceder a este modulo primero debes iniciar sesión.</p>
      <button class="bnt-primary" @click="$bvModal.show('modal-login')">
        Iniciar sesión
      </button>
    </div>
    <modal-direccion :is-edit="isEdit" :data-edit="dataEdit" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import ModalDireccion from '@/components/Templates/ModalDireccion'

export default {
  components: { ModalDireccion },
  data() {
    return {
      isEdit: false,
      dataEdit: null,
    }
  },
  computed: {
    ...mapState('user', ['directions', 'dataUser']),
  },
  methods: {
    ...mapActions('user', ['deleteDir', 'getDataProfile']),
    addDirUser() {
      this.isEdit = false
      this.dataEdit = null
      this.$bvModal.show('modal-directions')
    },
    updateDirUser(dataDir) {
      this.isEdit = true
      this.dataEdit = {
        idDir: dataDir.direccion_id,
        dir: dataDir.direccion,
        barrio: dataDir.barrio,
        dep: dataDir.departamento_id,
        mun: dataDir.ciudad_id,
        observaciones: dataDir.observaciones,
      }
      this.$bvModal.show('modal-directions')
    },
    async deleteDirection(idDir) {
      await this.deleteDir({ idDir: idDir }).then(async () => {
        await this.getDataProfile()
      })
    },
  },
}
</script>
